import { FC } from 'react';
import Image from 'components/image/Image';
import { Box, useTheme } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import BadgeProfiClientMobile from 'modules/theme/components/agency/agency/common/svg/BudgeProfiClientMobile.svg?url';

export const ProfiBadge: FC = () => {
    const { palette } = useTheme();

    return (
        <Box
            display={'flex'}
            alignItems="center"
            borderRadius={8}
            gap={1}
            p={0.5}
            sx={{
                backgroundColor: palette.backgroundSecondary.main,
            }}
        >
            <Image alt="" width={32} height={32} src={BadgeProfiClientMobile} />
            <Text bold variant={'label2'}>
                Profesionálny člen
            </Text>
            <Box width={10} />
        </Box>
    );
};
