import { FC } from 'react';
import { Box, useTheme } from '@mui/material';
import Image from 'components/image/Image';
import BudgeProfiClientDesktop from 'modules/theme/components/agency/agency/common/svg/BudgeProfiClientDesktop.svg?url';

export const ProfiBadge: FC = () => {
    const { palette } = useTheme();

    return (
        <Box
            display={'flex'}
            alignItems="center"
            borderRadius={8}
            gap={1}
            p={0.5}
            sx={{
                backgroundColor: palette.backgroundSecondary.main,
            }}
        >
            <Image
                alt=""
                width={48}
                height={48}
                src={BudgeProfiClientDesktop}
            />
        </Box>
    );
};
